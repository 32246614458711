import { router } from '@inertiajs/vue3'
import NProgress from 'nprogress'
import type { Plugin } from 'vue'
import { INERTIA_POLLING_RELOAD_HEADER_KEY } from '@/helpers/useInertiaPolling'

export const plugin = {
  install() {
    let timeout: number | null = null

    router.on('start', (event) => {
      if (!event.detail.visit.headers[INERTIA_POLLING_RELOAD_HEADER_KEY]) {
        timeout = window.setTimeout(() => NProgress.start(), 250)
      }
    })

    router.on('progress', (event) => {
      if (NProgress.isStarted() && event.detail.progress?.percentage) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        NProgress.set(Math.max(NProgress.status!, (event.detail.progress.percentage / 100) * 0.9))
      }
    })

    router.on('finish', (event) => {
      if (timeout) {
        window.clearTimeout(timeout)
      }

      if (!NProgress.isStarted()) {
        return
      } else if (event.detail.visit.completed) {
        NProgress.done()
      } else if (event.detail.visit.interrupted) {
        NProgress.set(0)
      } else if (event.detail.visit.cancelled) {
        NProgress.done()
        NProgress.remove()
      }
    })
  },
} satisfies Plugin
