import { createInertiaApp, router } from '@inertiajs/vue3'
import { plugin as ReportsContextPlugin, type TReportContext } from '@pentesttools/ui/plugins/reports/context'
import { AnalyticsBrowser } from '@segment/analytics-next'
import 'tippy.js/dist/tippy.css'
import 'nprogress/nprogress.css'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import { trail } from 'momentum-trail'
import { FetchError } from 'ofetch'
import { createPinia } from 'pinia'
import { createApp, h } from 'vue'
import { plugin as VueTippy } from 'vue-tippy'
import { useToast } from './helpers/useToast'
import { plugin as progress } from './plugins/progress'
import routes from './routes.json'

const pinia = createPinia()

createInertiaApp({
  progress: false,
  resolve: async (name) => {
    const pages = import.meta.glob('./Pages/**/*.vue')
    // nosemgrep: unsafe-dynamic-method
    const page = await pages[`./Pages/${name}.vue`]()
    // @ts-expect-error Page is not typed
    page.default.layout = await (async () => {
      // @ts-expect-error Page is not typed
      if (page.default.layout) {
        // @ts-expect-error Page is not typed
        return page.default.layout
      }

      if (name.startsWith('Guest/')) {
        return
      }

      const { default: DefaultLayout } = await import('./Layouts/default.vue')
      return DefaultLayout
    })()

    return page
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })

    if (import.meta.env.VITE_APP_ENV === 'production') {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_ENV,
        integrations: [
          Sentry.extraErrorDataIntegration(),
          Sentry.captureConsoleIntegration({ levels: ['error'] }),
          Sentry.httpClientIntegration(),
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],

        tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
        replaysSessionSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
        replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE),

        trackComponents: true,
        hooks: ['mount', 'update'],
        timeout: 2000,

        beforeSend(event, hint) {
          const error = hint.originalException

          // Discard issues where the user aborted the request or it failed due to network issues on the user's end
          if (
            (error instanceof FetchError || axios.isAxiosError(error)) &&
            ((error.message.match(/(network error)|(failed to fetch)/i) && !navigator.onLine) || error.message.match(/request aborted/i))
          ) {
            return null
          }

          return event
        },
      })

      if (props.initialPage.props.auth.user) {
        Sentry.setUser({
          id: props.initialPage.props.auth.user.id,
          email: props.initialPage.props.auth.user.email,
        })
      }
    }

    app
      .use(plugin)
      .use(pinia)
      .use(trail, { routes })
      .use(ReportsContextPlugin, {
        context: (props.initialPage.props.context as TReportContext) ?? 'app',
        timeZone: props.initialPage.props.auth.user?.time_zone ?? 'Europe/Bucharest',
      })
      .use(VueTippy, {
        directive: 'tippy',
        component: 'tippy',
        componentSingleton: 'tippy-singleton',
        defaultProps: {
          allowHTML: true,
        },
      })
      .use(progress)
      .use({
        install(app) {
          if (import.meta.env.VITE_APP_ENV === 'production') {
            const segment = AnalyticsBrowser.load({ writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY })

            if (props.initialPage.props.auth.user) {
              segment.identify(props.initialPage.props.auth.user.id.toString(), {
                email: props.initialPage.props.auth.user.email,
              })
            }

            app.provide('segment', segment)
          }
        },
      })
      .use({
        install() {
          const { api } = useToast()

          router.on('before', (event) => {
            if (!navigator.onLine) {
              event.preventDefault()
              api.create({
                type: 'warning',
                title: "Oops! Looks like you're offline.",
                description: 'Connect to the internet and give it another shot.',
              })
            }
          })
        },
      })
      .mount(el)
  },
})
